@import 'fonts';

html,
#__next,
main {
  margin: 0 !important;
  height: 100%;
}

* {
  //padding: 0;
  //margin: 0;
  box-sizing: border-box;
  font-family: $font-family-base;
}

.pageLayout {
  row-gap: 0 !important;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
