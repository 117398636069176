@import 'src/styles/breakpoints';

.keyword {
  &.hideOnMobile {
    @include respond_below(s) {
      display: none;
    }
  }

  &.transparent,
  &.transparent:hover {
    background-color: unset;
    cursor: none;
    padding: 0;
  }

  &.blackOnMobile {
    @include respond_below(s) {
      background-color: var(--color-black-80);
      color: var(--color-white);
    }
  }

  &.isToday {
    background-color: var(--color-summer-medium-light);
  }

  &.isFreeEvent {
    background-color: var(--color-keyword-free-event);
  }

  &.noActions:focus {
    box-shadow: none;
  }
}
