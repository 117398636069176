@charset "utf-8";

// Import Helsinki font files here. For licencing reasons font files are not publicly shared.
@font-face {
  font-family: 'HelsinkiGrotesk';
  font-display: swap;
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/565d73a693abe0776c801607ac28f0bf.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'HelsinkiGrotesk';
  font-display: swap;
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/5bb29e3b7b1d3ef30121229bbe67c3e1.woff')
    format('woff');
  font-weight: 400;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HelsinkiGrotesk';
  font-display: swap;
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/7c46f288e8133b87e6b12b45dac71865.woff')
    format('woff');
  font-weight: 500;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HelsinkiGrotesk';
  font-display: swap;
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/e62dc97e83a385e4d8cdc939cf1e4213.woff')
    format('woff');
  font-weight: 500;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HelsinkiGrotesk';
  font-display: swap;
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/533af26cf28d7660f24c2884d3c27eac.woff')
    format('woff');
  font-weight: 700;
  font-style: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'HelsinkiGrotesk';
  font-display: swap;
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/20d494430c87e15e194932b729d48270.woff')
    format('woff');
  font-weight: 700;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HelsinkiGrotesk';
  font-display: swap;
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/a50a1bd245ce63abcc0d1da80ff790d2.woff')
    format('woff');
  font-weight: 900;
  font-style: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'HelsinkiGrotesk';
  font-display: swap;
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/62a1781d8b396fbb025b0552cf6304d2.woff')
    format('woff');
  font-weight: 900;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

$hel-grotesk: 'HelsinkiGrotesk', Arial, -apple-system, BlinkMacSystemFont,
  'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;

// Application font:
$font-family-sans-serif: $hel-grotesk;
$font-family-base: $hel-grotesk;

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, 'Liberation Mono',
  'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;
