.infoRow {
  padding: var(--spacing-2-xs) 0;
  svg {
    margin-right: var(--spacing-2-xs);
    min-width: 24px;
  }

  button {
    margin: var(--spacing-3-xs);
  }

  .withIcon {
    display: flex;

    .text {
      line-height: var(--lineheight-l);
      font-size: var(--fontsize-body-l);
    }
  }
}

.tagCount {
  background-color: var(--color-white) !important;
}
