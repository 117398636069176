@import 'src/styles/breakpoints';

.errorPageWrapper {
  height: 100%;
  --link-color: var(--color-tram-dark);
  --hero-background-color: var(--color-theme-background);

  min-height: 20rem;
}

.errorPageHero {
  background: var(--color-theme-background);
  height: 100%;
  padding: 5.625rem var(--spacing-m) 10rem;
  text-align: center;
  box-sizing: border-box;

  @include respond_above(s) {
    padding-top: 10.375rem;
    padding-bottom: 10.375rem;
  }

  h1 {
    max-width: 700px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  p {
    font-size: 1.25rem;
    max-width: 610px;
    margin: 0 auto 2rem;
    line-height: 2rem;
  }

  .backButton {
    background-color: var(--color-input);
    border-color: var(--color-input) !important;
    &:hover {
      background-color: var(--color-input-dark);
      border-color: var(--color-input-dark) !important;
    }
  }

  button {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}
