.helsinkiCityOwnedSpan {
  white-space: nowrap;
}

.helsinkiCityOwnedIcon {
  margin-bottom: 0.1em;
  margin-left: 0.1em;
  color: var(--color-coat-of-arms);
  vertical-align: middle;
}
